import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { faUser, faGlobe, faCaretDown, faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { TokenPlanta } from 'src/app/model/autenticacion';
import { Menu } from 'src/app/model/menu';
import { MenuService } from 'src/app/services/menu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  menus: Menu[] = null;
  plantas: TokenPlanta[];
  plantaSel: TokenPlanta = new TokenPlanta();
  private tokenSel: string;
  logoUrl: string;
  login: string;
  faUser = faUser;
  faGlobe = faGlobe;
  faCaretDown = faCaretDown;
  faLogout = faSignOutAlt;
  faBars = faBars;

  isMobileLayout = false;

  colorAlcoaMenu: string;

  @Output() loggedEvent = new EventEmitter<boolean>();

  constructor(@Inject(DOCUMENT) private document: Document, private route: Router, private menuService: MenuService, public translate: TranslateService, private dateAdapter: DateAdapter<Date>) {
    this.translate.use('es');
    this.colorAlcoaMenu = environment.colorMenu;
    document.documentElement.style.setProperty('--colorAlcoaMenu', this.colorAlcoaMenu);
    document.documentElement.style.setProperty('--mat-toolbar-container-text-color', "white");
  }

  ngOnInit(): void {
    this.isMobileLayout = window.innerWidth <= 1175;
    window.onresize = () => this.isMobileLayout = window.innerWidth <= 1175;
    this.switchLang("es");
    this.logoUrl = `${environment.serverUrl}images/AlcoaLogo.png`;
    this.login = localStorage.getItem("loginAtenciones") ? localStorage.getItem("loginAtenciones") : sessionStorage.getItem("loginAtenciones");
    let pAcceso = localStorage.getItem("plantasAccesoAtenciones") ? localStorage.getItem("plantasAccesoAtenciones") : sessionStorage.getItem("plantasAccesoAtenciones");
    this.plantas = JSON.parse(pAcceso);
    if (this.plantas != undefined) {
      // Se mira el token que esta selecionado para seleccionar la planta correcta
      this.tokenSel = localStorage.getItem("atencionesSocialesToken") ? localStorage.getItem("atencionesSocialesToken") : sessionStorage.getItem("atencionesSocialesToken");
      let ps = localStorage.getItem("plantaSelAtenciones") ? localStorage.getItem("plantaSelAtenciones") : sessionStorage.getItem("plantaSelAtenciones");
      if (ps != null && ps.length > 0) {
        this.plantaSel = JSON.parse(ps);
      } else {
        this.plantaSel = this.plantas[0];
      }
      this.seleccionarPlanta();
      this.plantas.forEach(p => {
        if (this.tokenSel == p.token) {
          this.plantaSel = p;
        }
      });
      this.menuService.getMenus().subscribe({
        next: menus => {
          this.menus = menus;
          if (this.plantaSel.planta.startsWith("Inespal")) {
            this.menus.forEach(m => {
                m.submenus = m.submenus.filter(sm => ![1640,1641].includes(sm.id));
            });
          }
        },
        error: (error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            if (error.status == 401) {
              this.loggedEvent.emit(false);
              localStorage.clear();
              sessionStorage.clear();
              this.route.navigateByUrl('/');
            }
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
        }
      });
    }
  }

  cargarPagina(ruta: string) {
    this.route.navigateByUrl(ruta);
  }

  cerrarSesion() {
    this.loggedEvent.emit(false);
    localStorage.clear();
    sessionStorage.clear();
    this.route.navigateByUrl('/');

  }

  seleccionarPlanta() {
    let roles: string = this.plantaSel.rol;
    let rolesUsuario: string[] = roles.split(",");
    if (rolesUsuario.length == 1 && rolesUsuario.includes("usuario")) {
      localStorage.removeItem("atencionesSocialesToken");
      localStorage.removeItem("plantaSelAtenciones");
      sessionStorage.setItem("atencionesSocialesToken", this.plantaSel.token);
      sessionStorage.setItem("plantaSelAtenciones", JSON.stringify(this.plantaSel));
    } else {
      localStorage.setItem("atencionesSocialesToken", this.plantaSel.token);
      localStorage.setItem("plantaSelAtenciones", JSON.stringify(this.plantaSel));
    }
    this.menuService.getMenus().subscribe({
      next: menus => {
        this.menus = menus;        
        if (this.plantaSel.planta.startsWith("Inespal")) {
          this.menus.forEach(m => {
              m.submenus = m.submenus.filter(sm => ![1640,1641].includes(sm.id));
          });
        }
        this.route.navigateByUrl('/');
      }
    });
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    this.dateAdapter.setLocale(lang);
  }
}

