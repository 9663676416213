import { BrowserModule } from '@angular/platform-browser';
import {  NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalService, ProtectedResourceScopes } from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { MenuComponent } from './components/menu/menu.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { DatePipe, registerLocaleData } from '@angular/common';
import { AyudaComponent } from './components/ayudas/ayuda/ayuda.component';
import { BeneficiarioComponent } from './components/ayudas/beneficiario/beneficiario.component';
import { CierreComponent } from './components/ayudas/cierre/cierre.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { AvisodialogComponent } from './components/dialog/avisodialog/avisodialog.component';
import { ConfirmaciondialogComponent } from './components/dialog/confirmaciondialog/confirmaciondialog.component';
import { TipoayudasComponent } from './components/ayudas/tipoayudas/tipoayudas.component';
import { NuevotipoayudadialogComponent } from './components/ayudas/tipoayudas/nuevotipoayudadialog/nuevotipoayudadialog.component';
import { AprobacionhrComponent } from './components/ayudas/aprobacionhr/aprobacionhr.component';
import { DenegardialogComponent } from './components/ayudas/aprobacionhr/denegardialog/denegardialog.component';
import { InformeayudasComponent } from './components/informes/informeayudas/informeayudas.component';
import { InformenominasComponent } from './components/informes/informenominas/informenominas.component';
import { UsuariosdialogComponent } from './components/ayudas/ayuda/usuariosdialog/usuariosdialog.component';
import { RevisarayudasComponent } from './components/ayudas/revisarayudas/revisarayudas.component';
import { RevisardialogComponent } from './components/ayudas/revisarayudas/revisardialog/revisardialog.component';

import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import { EsperedialogComponent } from './components/dialog/esperedialog/esperedialog.component';
import { MatPaginationIntlService } from './mat-paginator-intl-service';
import { PresupuestoComponent } from './components/ayudas/presupuesto/presupuesto.component';
import { BuscadorempleadoComponent } from './components/genericos/buscadorempleado/buscadorempleado.component';
import { RevisarpresupuestosComponent } from './components/ayudas/revisarpresupuestos/revisarpresupuestos.component';
import { InformepresupuestosComponent } from './components/informes/informepresupuestos/informepresupuestos.component';
import { BajasComponent } from './components/bajas/bajas/bajas.component';
import { RevisarbajasComponent } from './components/bajas/revisarbajas/revisarbajas.component';
import { InformebajasComponent } from './components/informes/informebajas/informebajas.component';
import { BajadialogComponent } from './components/bajas/revisarbajas/bajadialog/bajadialog.component';
import { EstudiosComponent } from './components/ayudas/estudios/estudios.component';
import { EstudiosdialogComponent } from './components/ayudas/estudios/estudiosdialog/estudiosdialog.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatChipsModule} from '@angular/material/chips';
import { SolicitudComponent } from './components/ayudas/campamentos/solicitud/solicitud.component';
import { RevisionComponent } from './components/ayudas/campamentos/revision/revision.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

registerLocaleData(localeEs, 'es');
registerLocaleData(localeEn, 'en');

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
      auth: {
          clientId: environment.adClientId, // This is the ONLY mandatory field that you need to supply.
          authority: `https://login.microsoftonline.com/${environment.adTenantId}`, // Defaults to "https://login.microsoftonline.com/common"
          redirectUri: `${window.location.origin}${environment.baseUrl}`, // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
          postLogoutRedirectUri: `${window.location.origin}${environment.baseUrl}`, // Points to window.location.origin by default.
          clientCapabilities: ['CP1'] // This lets the resource server know that this client can handle claim challenges.
      }
  });
}

/**
* Set your default interaction type for MSALGuard here. If you have any
* additional scopes you want the user to consent upon login, add them here as well.
*/
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
      interactionType: InteractionType.Popup,
      authRequest: {
          redirectUri: `${window.location.origin}${environment.baseUrl}`,
          scopes: []
      }
  };
}

/**
* MSAL Angular will automatically retrieve tokens for resources
* added to protectedResourceMap. For more info, visit:
* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
*/
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
return {
    interactionType: InteractionType.Popup,
    protectedResourceMap: new Map<string, Array<string | ProtectedResourceScopes> | null>()
};
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenuComponent,
    AyudaComponent,
    BeneficiarioComponent,
    CierreComponent,
    AvisodialogComponent,
    ConfirmaciondialogComponent,
    TipoayudasComponent,
    NuevotipoayudadialogComponent,
    AprobacionhrComponent,
    DenegardialogComponent,
    InformeayudasComponent,
    InformenominasComponent,
    UsuariosdialogComponent,
    RevisarayudasComponent,
    RevisardialogComponent,
    EsperedialogComponent,
    PresupuestoComponent,
    BuscadorempleadoComponent,
    RevisarpresupuestosComponent,
    InformepresupuestosComponent,
    BajasComponent,
    RevisarbajasComponent,
    InformebajasComponent,
    BajadialogComponent,
    EstudiosComponent,
    EstudiosdialogComponent,
    SolicitudComponent,
    RevisionComponent
  ],
  imports: [
    FontAwesomeModule,
    BrowserModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatChipsModule,
    NgHttpLoaderModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConfigInterceptor,
      multi: true
    },
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginationIntlService,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
    },
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    },
    {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `${environment.baseUrl}assets/i18n/`, '.json');
}
