<mat-toolbar class="bg-alcoa cabecera">
    <mat-toolbar-row>
        <a class="navbar-brand" href="#">
            <img [src]="'assets/img/AlcoaLogo.png'" style="height: 50px;">
        </a>
        <span class="separador-vertical ms-2"></span>
        <span class="ms-2 cont-title-text">{{ 'HEADER.APP' | translate }}</span>
        <span class="ms-1" *ngIf="!isMobileLayout">
            <select [(ngModel)]="plantaSel" class="combo-planta" (ngModelChange)="seleccionarPlanta()">
                <option *ngFor="let planta of plantas" [ngValue]="planta">{{planta.planta}}</option>
            </select>
        </span>
        <span *ngIf="!isMobileLayout">
            <span *ngFor="let menu of menus">
                <button mat-button class="text-light" [matMenuTriggerFor]="men" class="ms-1">{{ menu.nombre | translate }} <fa-icon
                        [icon]="faCaretDown"></fa-icon></button>
                <mat-menu #men="matMenu">
                    <button mat-menu-item class="text-alcoa" *ngFor="let submenu of menu.submenus"
                        (click)="cargarPagina(submenu.referencia)">{{ submenu.nombre | translate }}</button>
                </mat-menu>
            </span>
        </span>
        <span class="example-spacer"></span>
        <span *ngIf="!isMobileLayout">
            <div class="language-block me-2 align-middle" style="min-width: 130px;">
                <span>
                    <span style="font-size: 35px;" class="me-2">
                        <fa-icon [icon]="faGlobe"></fa-icon>
                    </span>
                    <span class="language" [ngClass]="'es' === translate.currentLang ? 'selected-language' : ''"
                        (click)="switchLang('es')">es</span>
                    <span class="language" [ngClass]="'en' === translate.currentLang ? 'selected-language' : ''"
                        (click)="switchLang('en')">en</span>
                </span>
            </div>
        </span>
        <span *ngIf="isMobileLayout" class="example-spacer"></span>
        <span>
            <button mat-button [matMenuTriggerFor]="logout" class="btn btn-alcoa" data-toggle="tooltip"
                data-placement="bottom" *ngIf="!isMobileLayout">
                {{ login }} <fa-icon class="btn-alcoa-PMS320 btn-user-nombre rounded-circle text-alcoa ms-1"
                    [icon]="faUser"></fa-icon>
            </button>
            <button mat-button [matMenuTriggerFor]="logout" class="btn btn-alcoa" data-toggle="tooltip"
                data-placement="bottom" *ngIf="isMobileLayout" [matTooltip]="login">
                <fa-icon class="btn-alcoa-PMS320 btn-user-nombre rounded-circle text-alcoa ms-1"
                    [icon]="faUser"></fa-icon>
            </button>
            <mat-menu #logout="matMenu" class="text-center">
                <label class="form-label text-alcoa fw-bold" *ngIf="isMobileLayout">{{ login }}</label>
                <button mat-menu-item class="text-alcoa" title="{{ 'logout' | translate }}" (click)="cerrarSesion()">{{
                    'MENU.LOGOUT' | translate }} <fa-icon [icon]="faLogout"></fa-icon></button>
            </mat-menu>
        </span>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="isMobileLayout">
        <button mat-button [matMenuTriggerFor]="principal"><fa-icon [icon]="faBars"></fa-icon></button>
        <mat-menu #principal="matMenu">
            <span *ngFor="let menu of menus">
                <button mat-menu-item [matMenuTriggerFor]="men" class="ms-1">{{ menu.nombre | translate }} </button>
                <mat-menu #men="matMenu">
                    <button mat-menu-item class="text-alcoa" *ngFor="let submenu of menu.submenus"
                        (click)="cargarPagina(submenu.referencia)">{{ submenu.nombre | translate }}</button>
                </mat-menu>
            </span>
        </mat-menu>
        <span class="ms-1">
            <select [(ngModel)]="plantaSel" class="combo-planta-sm" (ngModelChange)="seleccionarPlanta()">
                <option *ngFor="let planta of plantas" [ngValue]="planta">{{planta.planta}}</option>
            </select>
        </span>
        <span class="example-spacer"></span>
        <div class="language-block me-2 align-middle" style="min-width: 130px;">
            <span>
                <span class="me-2 globe">
                    <fa-icon [icon]="faGlobe"></fa-icon>
                </span>
                <span class="language" [ngClass]="'es' === translate.currentLang ? 'selected-language' : ''"
                    (click)="switchLang('es')">es</span>
                <span class="language" [ngClass]="'en' === translate.currentLang ? 'selected-language' : ''"
                    (click)="switchLang('en')">en</span>
            </span>
        </div>
    </mat-toolbar-row>
</mat-toolbar>