<div class="container-fluid text-alcoa">
    <div class="row border-bottom border-alcoa">
        <h1 class="col-12 text-alcoa">Informes</h1>
    </div>
    <form class="row pt-2">
        <div class="col-2">
            <mat-form-field class="form-control">
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate formControlName="start" [placeholder]="'FORM.FECHA_INICIO' | translate"
                        readonly>
                    <input matEndDate formControlName="end" [placeholder]="'FORM.FECHA_FIN' | translate" readonly>
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
        <div class="col-2">
            <label class="form-label fw-bold">Tipo Contrato</label>
            <mat-select class="form-control" [(ngModel)]="tipoConvenio" name="tipoConvenio"
                (selectionChange)="buscarTipoAyuda()">
                <mat-option></mat-option>
                <mat-option [value]="tipoContrato.value"
                    *ngFor="let tipoContrato of tipoContratos">{{tipoContrato.label}}</mat-option>
            </mat-select>
        </div>
        <div class="col-2">
            <label class="form-label fw-bold">Tipo Ayuda</label>
            <mat-select class="form-control" [(ngModel)]="tipoAyuda" name="tipoAyuda" [disabled]="!tipoConvenio"
                multiple>
                <mat-option [value]="tipoA.idTipoAyudaContrato"
                    *ngFor="let tipoA of tipoAyudas">{{tipoA.descripcion}}</mat-option>
            </mat-select>
        </div>
        <div class="col-1">
            <label class="form-label fw-bold">Expediente</label>
            <input type="number" class="form-control" [(ngModel)]="expediente" name="expediente">
        </div>
        <div class="col-3">
            <label class="form-label fw-bold">Nombre</label>
            <input type="text" class="form-control" [(ngModel)]="nombre" name="nombre">
        </div>
        <div class="col-1">
            <label class="form-label fw-bold">Aprobadas</label>
            <mat-slide-toggle color="primary" class="form-control border-none" [(ngModel)]="aprobadas" name="aprobadas"></mat-slide-toggle>
        </div>
        <div class="col-1">
            <button type="button" class="btn btn-alcoa float-end mb-1" (click)="buscar()"><fa-icon
                    [icon]="faSearch"></fa-icon> {{ 'BOTONES.BUSCAR' | translate }}</button>
            <button type="button" class="btn btn-alcoa float-end" (click)="limpiar()"><fa-icon
                    [icon]="faEraser"></fa-icon> {{ 'BOTONES.LIMPIAR' | translate }}</button>
        </div>
    </form>
    <div class="row">
        <div class="col-1 text-start">
            <button class="btn btn-excel" (click)="exportar()"><fa-icon [icon]="faExcel"></fa-icon></button>
        </div>
    </div>
    <table mat-table [dataSource]="dataSource" matSort
        class="table table-condensed table-striped table-bordered stripe text-alcoa align-middle mt-3">
        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'FORM.FECHA' | translate }} </th>
            <td mat-cell *matCellDef="let element" class="w10"> {{element.fecha | date : 'dd-MM-yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="ayuda">
            <th mat-header-cell *matHeaderCellDef> {{'FORM.AYUDA' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="ps-3 w15"> {{ element.descTipoAyuda }} </td>
        </ng-container>
        <ng-container matColumnDef="contrato">
            <th mat-header-cell *matHeaderCellDef> {{'MODAL.TIPO_CONTRATO' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="ps-3 w10">{{element.convenio}}</td>
        </ng-container>
        <ng-container matColumnDef="expediente">
            <th mat-header-cell *matHeaderCellDef> {{'FORM.EXPEDIENTE' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="ps-3 w5"> {{ element.expediente }} </td>
        </ng-container>
        <ng-container matColumnDef="empleado">
            <th mat-header-cell *matHeaderCellDef> {{'FORM.EMPLEADO' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="ps-3 w15"> {{ element.empleado }} </td>
        </ng-container>
        <ng-container matColumnDef="planta">
            <th mat-header-cell *matHeaderCellDef> {{'FORM.PLANTA' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="ps-3 w10"> {{ element.planta }} </td>
        </ng-container>
        <ng-container matColumnDef="convenio">
            <th mat-header-cell *matHeaderCellDef> PIC/PFD</th>
            <td mat-cell *matCellDef="let element" class="ps-3 w5"> {{ element.convenio }} </td>
        </ng-container>
        <ng-container matColumnDef="beneficiario">
            <th mat-header-cell *matHeaderCellDef> {{'FORM.BENEFICIARIO' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="ps-3 w15"> {{ element.beneficiario }} </td>
        </ng-container>
        <ng-container matColumnDef="importe">
            <th mat-header-cell *matHeaderCellDef> {{'FORM.IMPORTE_AYUDA' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="text-end pe-3 w5"> {{ element.importe | number:'1.2-2' }}
            </td>
        </ng-container>
        <ng-container matColumnDef="observaciones">
            <th mat-header-cell *matHeaderCellDef> {{'FORM.OBSERVACIONES' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="ps-3"> {{element.estado == 0 ? "Pendiente Revisar People
                Service" : element.observaciones }} </td>
        </ng-container>
        <ng-container matColumnDef="mes">
            <th mat-header-cell *matHeaderCellDef> {{'FORM.MES_NOMINA' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="pe-3 w5 text-end"> {{ element.mesCierre | number:'2.0-0'
                }}{{element.mesCierre ? '-':''}}{{element.anoCierre}}</td>
        </ng-container>
        <ng-container matColumnDef="adjunto">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="w5 text-center">
                <button class="btn btn-sm btn-alcoa-transparente" style="font-size:10px;" (click)="verFichero(element)"
                    *ngIf="element.nombreFichero">
                    <fa-icon [icon]="faPaperClip"></fa-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"> </tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"> </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
</div>