import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Ayuda } from '../model/ayuda';
import { Coeficiente } from '../model/coeficiente';
import { Curso } from '../model/curso';
import { LabelValue } from '../model/labelvalue';
import { Nomina } from '../model/nomina';
import { Presupuesto } from '../model/presupuesto';
import { TipoAyudaConContrato, TipoAyudaContrato } from '../model/tipoayuda';

@Injectable({
  providedIn: 'root'
})
export class AyudasService {
  URL: string = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  getTipoAyudas(idTipoContrato: number) : Observable<TipoAyudaContrato[]> {
    return this.httpClient.get<TipoAyudaContrato[]>(`${this.URL}api/TipoAyudas/${idTipoContrato}`);
  }

  altaTipoAyudas(tipoAyuda: TipoAyudaConContrato) : Observable<LabelValue[]> {
    return this.httpClient.put<LabelValue[]>(`${this.URL}api/TipoAyudas`, tipoAyuda);
  }

  borraTipoAyudas(idTipoAyuda: number) : Observable<LabelValue[]> {
    return this.httpClient.delete<LabelValue[]>(`${this.URL}api/TipoAyudas/${idTipoAyuda}`);
  }

  modificaTipoAyudas(tipoAyuda: TipoAyudaConContrato) : Observable<LabelValue[]> {
    return this.httpClient.post<LabelValue[]>(`${this.URL}api/TipoAyudas`, tipoAyuda);
  }

  getTipoAyudasConContrato() : Observable<TipoAyudaConContrato[]> {
    return this.httpClient.get<TipoAyudaConContrato[]>(`${this.URL}api/TipoAyudas`);
  }

  getTipoAyudasSinContrato() : Observable<TipoAyudaConContrato[]> {
    return this.httpClient.get<TipoAyudaConContrato[]>(`${this.URL}api/TipoAyudas/sincontrato`);
  }

  getTipoContratos() : Observable<LabelValue[]> {
    return this.httpClient.get<LabelValue[]>(`${this.URL}api/TipoAyudaContratos`);
  }

  getAyudas(idUsuario: number) : Observable<Ayuda[]> {
    return this.httpClient.get<Ayuda[]>(`${this.URL}api/Ayudas/${idUsuario}`);
  }

  getAyudasMes(mes: number, ano: number) : Observable<Ayuda[]> {
    return this.httpClient.get<Ayuda[]>(`${this.URL}api/Ayudas/${mes}/${ano}`);
  }

  getAyudasPendientes() : Observable<Ayuda[]> {
    return this.httpClient.get<Ayuda[]>(`${this.URL}api/Ayudas/pendientes`);
  }

  altaAyuda(ayuda: Ayuda): Observable<Ayuda> {
    return this.httpClient.post<Ayuda>(`${this.URL}api/Ayudas`, ayuda);
  }

  modificaAyuda(ayuda: Ayuda): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.URL}api/Ayudas`, ayuda);
  }

  borraAyuda(idAyuda: number): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.URL}api/Ayudas/${idAyuda}`);
  }

  getAyudasAdjunto(idAyudaAdjunto: number) : Observable<Blob> {
    return this.httpClient.get<Blob>(`${this.URL}api/AyudaAdjuntos/${idAyudaAdjunto}`, { observe: 'body', responseType: 'blob' as 'json' });
  }

  altaAyudaAdjunto(ayuda: Ayuda, fileToUpload: File): Observable<number> {
    const formData: FormData = new FormData();
    formData.append('adjunto', fileToUpload, fileToUpload.name);
    formData.append('idAyuda', JSON.stringify(ayuda.idAyuda));
    return this.httpClient.post<number>(`${this.URL}api/AyudaAdjuntos`, formData);
  }

  deleteAyudaAdjunto(idAyudaAdjunto: number): Observable<number> {
    return this.httpClient.delete<number>(`${this.URL}api/AyudaAdjuntos/${idAyudaAdjunto}`);
  }

  getAyudasNomina(mes: number, ano: number) : Observable<Nomina[]> {
    return this.httpClient.get<Nomina[]>(`${this.URL}api/Informes/nomina/${mes}/${ano}`);
  }

  getAyudasInforme(aprobadas: boolean, fechaIni?: string, fechaFin?: string, tipoConvenio?: number, tipoAyuda?: number[], expediente?: number, nombre?: string) : Observable<Ayuda[]> {
    let strQuery = `aprobadas=${aprobadas}&`;
    if (fechaIni != null) {
      strQuery += `fechaInicio=${fechaIni}&`;
    }
    if (fechaFin != null) {
      strQuery += `fechaFin=${fechaFin}&`;
    }
    if (tipoConvenio != null) {
      strQuery += `tipoConvenio=${tipoConvenio}&`;
    }
    if (tipoAyuda != null) {
      tipoAyuda.forEach(ta => strQuery += `tipoAyuda=${ta}&`);
    }
    if (expediente != null) {
      strQuery += `expediente=${expediente}&`;
    }
    if (nombre != null && nombre.length > 0) {
      strQuery += `nombre=${nombre}&`;
    }
    return this.httpClient.get<Ayuda[]>(`${this.URL}api/Informes?${strQuery}`);
  }

  getPresupuestosInforme(fechaIni?: string, fechaFin?: string, estado?: number, tipoAyuda?: number[], expediente?: number, nombre?: string) : Observable<Presupuesto[]> {
    let strQuery = "";
    if (fechaIni != null) {
      strQuery += `fechaInicio=${fechaIni}&`;
    }
    if (fechaFin != null) {
      strQuery += `fechaFin=${fechaFin}&`;
    }
    if (estado != null) {
      strQuery += `estado=${estado}&`;
    }
    if (tipoAyuda != null) {
      tipoAyuda.forEach(ta => strQuery += `tipoAyuda=${ta}&`);
    }
    if (expediente != null) {
      strQuery += `expediente=${expediente}&`;
    }
    if (nombre != null && nombre.length > 0) {
      strQuery += `nombre=${nombre}&`;
    }
    return this.httpClient.get<Presupuesto[]>(`${this.URL}api/Informes/presupuestos?${strQuery}`);
  }

  enviarMailAyuda(ayuda: Ayuda) {
    return this.httpClient.post(`${this.URL}api/Email/ayuda/denegar`, ayuda);
  }

  getCursos(): Observable<LabelValue[]> {
      return this.httpClient.get<LabelValue[]>(`${this.URL}api/Cursos`);
  }

  addCurso(curso: LabelValue): Observable<LabelValue> {
    return this.httpClient.post<LabelValue>(`${this.URL}api/Cursos/`, curso);
  }

  updateCurso(curso: Curso): Observable<Curso> {
    return this.httpClient.put<Curso>(`${this.URL}api/Cursos/`, curso);
  }

  deleteCurso(idCurso: number): Observable<Curso> {
    return this.httpClient.delete<Curso>(`${this.URL}api/Cursos/${idCurso}`);
  }

  getCoeficientes(): Observable<Coeficiente[]> {
    return this.httpClient.get<Coeficiente[]>(`${this.URL}api/Cursos/coeficientes`);
  }

  updateCoeficientes(coeficiente: Coeficiente): Observable<Curso> {
    return this.httpClient.put<Curso>(`${this.URL}api/Cursos/coeficientes`, coeficiente);
  }

  getAyudaEstudios(idTipoAyuda?: number): Observable<Curso[]> {
    if (idTipoAyuda) {
      return this.httpClient.get<Curso[]>(`${this.URL}api/Cursos/ayudaestudios/${idTipoAyuda}`);
    } else {
      return this.httpClient.get<Curso[]>(`${this.URL}api/Cursos/ayudaestudios`);
    }
  }

  addAyudaEstudios(curso: Curso): Observable<Curso> {
    return this.httpClient.post<Curso>(`${this.URL}api/Cursos/ayudaestudios`, curso);
  }

  updateAyudaEstudios(curso: Curso): Observable<Curso> {
    return this.httpClient.put<Curso>(`${this.URL}api/Cursos/ayudaestudios`, curso);
  }

  deleteAyudaEstudios(curso: Curso): Observable<Curso> {
    return this.httpClient.delete<Curso>(`${this.URL}api/Cursos/ayudaestudios/${curso.idAyudaEstudiosCurso}`);
  }
}
